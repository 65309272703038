import { Slider, SliderProvider } from "solid-slider";

import "../styles/slider.css";
import "solid-slider/slider.css";
import type { CollectionEntry } from "astro:content";

type PostThumbnail = Pick<CollectionEntry<"blog">, "slug"> &
  Pick<CollectionEntry<"blog">["data"]["heroImage"], "src"> &
  Pick<CollectionEntry<"blog">["data"], "draft">;

type Props = {
  posts: PostThumbnail[];
  top?: boolean;
};

export const AutomoveThumbnailCarousel = ({ posts, top }: Props) => {
  return (
    <div
      class="absolute left-0 h-16 w-full cursor-grab pt-1 opacity-30 transition-opacity delay-150 duration-500 hover:opacity-100 hover:delay-0"
      classList={{
        "top-14": top,
      }}
    >
      <SliderProvider>
        <Slider
          options={{
            breakpoints: {
              "(min-width: 300px)": {
                slides: { perView: 2.5, spacing: 7 },
              },
              "(min-width: 360px)": {
                slides: { perView: 2.8, spacing: 7 },
              },
              "(min-width: 450px)": {
                slides: { perView: 3.2, spacing: 7 },
              },
              "(min-width: 550px)": {
                slides: { perView: 3.5, spacing: 7 },
              },
              "(min-width: 600px)": {
                slides: { perView: 3.8, spacing: 7 },
              },
              "(min-width: 700px)": {
                slides: { perView: 4.3, spacing: 7 },
              },
              "(min-width: 800px)": {
                slides: { perView: 4.8, spacing: 7 },
              },
              "(min-width: 900px)": {
                slides: { perView: 5.5, spacing: 7 },
              },
              "(min-width: 1500px)": {
                slides: { perView: 6, spacing: 7 },
              },
              "(min-width: 1800px)": {
                slides: { perView: 7, spacing: 7 },
              },
              "(min-width: 2000px)": {
                slides: { perView: 8, spacing: 7 },
              },
              "(min-width: 2600px)": {
                slides: {
                  perView: Math.min(
                    Math.max([...posts, ...posts, ...posts].length - 1, 1),
                    12
                  ),
                  spacing: 7,
                },
              },
            },
            loop: true,
            slides: { perView: 1.5, spacing: 5 },
          }}
          plugins={[
            (slider) => {
              const animation = { duration: 150000, easing: (t: number) => t };
              type SliderInstance = Parameters<
                Parameters<typeof slider.on>[1]
              >[0];

              const update = (s: SliderInstance) => {
                s.moveToIdx(s.track.details.abs + 5, true, animation);
              };

              slider.on("created", (s: SliderInstance) => {
                s.moveToIdx(5, true, animation);

                // slider.container.addEventListener("mouseover", () =>
                //   // s.animator.stop()
                // );
                // slider.container.addEventListener("mouseout", () =>
                //   // s.moveToIdx(5, true, animation)
                // );
              });
              slider.on("slideChanged", update);
              slider.on("updated", update);
              slider.on("animationEnded", update);
            },
          ]}
        >
          {[...posts, ...posts, ...posts].map((post) => {
            const image = (
              <div
                class={`z-10 h-8 w-full rounded-sm bg-cover bg-center active:cursor-grabbing min-[395px]:h-16`}
                style={`background-image: url('${post.src}'`}
              ></div>
            );

            return (
              <li class="flex flex-col">
                {post.draft ? (
                  image
                ) : (
                  <a href={`/blog/${post.slug}`} class="active:cursor-grabbing">
                    {image}
                  </a>
                )}
              </li>
            );
          })}
        </Slider>
      </SliderProvider>
    </div>
  );
};
